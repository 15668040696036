const account = {
    profile: "Profile",
    rolesAndPermission: "Roles and Permission",
    billing: "Billing",
    setting: "Setting",
    // profile
    corporateInformation: "Corporate Information",
    personalInformation: "Personal Information",
    // Roles and Permission
    addANewUser: "Add a new user",
    name: "Name",
    designation: "Designation",
    emailAddress: "Email address",
    permission: "Permission",
    status: "Status",
    read: "Read",
    action: "Action",
    roles: "Roles",
    userInformation: "User Information",
    // add user
    addANewUser: "Add a new user",
    provideDetails: "Please provide the details",
    firstName: "First Name",
    middleNameOptional: "Middle Name (optional)",
    lastName: "Last Name",
    dateOfBirth: "Date of birth",
    occupation: "Occupation",
    percentageOfOwnership: "% of Ownership",
    address: "Address",
    city: "City",
    country: "Country",
    provinceState: "Province / State",
    postalCode: "Postal Code",
    phoneNumber: "Phone number",
    email: "Email",
    instantMessageAppOptional: "Instant message App (optional)",
    appID: "App ID",
    permission: "Permission",
    admin: "Admin",
    initiator: "Initiator",
    approver: "Approver",
    teamMember: "Team Member",
    read: "Read",
    initiate: "Initiate",
    approve: "Approve",
    userManagement: "User Management",
    readDes: "View all transaction and download reports",
    beneficiaryCreation: "Beneficiary creation",
    transactionCreation: "Transaction creation",
    eft: "EFT",
    batchEFT: "Batch EFT",
    wire: "Wire",
    viewAllTransaction: "View all transaction",
    downloadReports: "Download reports",
    approvalAuthority: "Approval Authority",
    pepQuestion: "Is this individual a politically exposed person (PEP) as defined by FATF guidelines?",
    yes: "Yes",
    no: "No",
    uploadDocuments: "Upload Documents",
    identification: "Identification",
    idInstructions: "Passport, Driver Licence front and back or other government issued ID",
    proofOfAddress: "Proof of Address",
    proofOfAddressInstructions: "Within 3 months original PDF version utility bill or bank statement",
    submit: "Submit",
    onboardingRequestSubmitted: "Onboarding request submitted",
    onboardingRequestMessage: "A new user onboarding request has been submitted and requires approval from another administrator.",
    done: "Done",
    tutorial: "Tutorial",
    faq: "FAQ",
    account: "Account",
    signOut: "Sign out",
    // setting
    password: "Password",
    loginPassword: "Login Password",
    verification: "Verification",
    googleMicrosoftAuthenticator: "Google / Microsoft Authenticator",
    update: "Update",
    delete: "Delete",
    setNewLoginPassword: "Set Your New Log in Password",
    enterAllInput: "Please enter all the following input.",
    currentPassword: "Current Password",
    emailVerification: "Email Verification",
    emailVerificationCode: "Email Verification Code",
    newPassword: "New password",
    smsVerification: "SMS Verification",
    smsVerificationCode: "SMS Verification Code",
    confirmNewPassword: "Confirm New Password",
    notBound:'Not Bound',
    googleMicrosoftAuthenticatorCode: "Google / Microsoft Authenticator Code",
    verification: "Verification",
    enterAllCodes: "Please enter all the following codes to complete.",
    resetToLogin: "Reset password successfully, please login again",
    enterTheCode: "Please enter the code",
    allSet: "All set!",
    verificationUpdated: "Your verification code has been updated.",
    confrim: "Confirm",
    back: "Back",
    feesStatement: "Fees Statement",
    invoiceNum: "Invoice #",
    date: "Date", 
    doesNotMatch: `Password does not match.`,
    twoFactorAuthRequired:'2FA Required',
    twoFactorAuthSetupWarning:'You must have at least one active form of two-factor authentication (2FA). Please set up another 2FA method before unlinking the authenticator app.',
};

export default account;
