export const WealthLogo = ({ ...props }) => (
    <svg
        width="260"
        height="42"
        viewBox="0 0 260 42"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_144_5163)">
            <path
                d="M30.0302 16.2043C34.17 16.2043 37.5261 12.5769 37.5261 8.10216C37.5261 3.62746 34.17 0 30.0302 0C25.8903 0 22.5343 3.62746 22.5343 8.10216C22.5343 12.5769 25.8903 16.2043 30.0302 16.2043Z"
                fill="#C9BA96"
            />
            <path
                d="M10.2083 2.46465C8.40198 -0.212871 4.92905 -0.800028 2.45241 1.15313C-0.0242293 3.10628 -0.56748 6.86025 1.2398 9.53777L15.3061 30.3728C17.1134 33.0503 20.5853 33.6374 23.0668 31.6843C25.5445 29.7321 26.0877 25.9782 24.2804 23.3017L10.2083 2.46465Z"
                fill="#C9BA96"
            />
            <path
                d="M64.414 24.2228L56.8104 2.34055L50.2807 2.3688L60.6966 30.0116C61.2942 31.7176 62.8007 32.8415 64.48 32.8364C66.1592 32.8314 67.6609 31.6894 68.2439 29.9763L78.4221 2.2326L71.9342 2.25984L64.414 24.2228Z"
                fill="#05004D"
            />
            <path
                d="M85.5705 11.1228L79.8635 11.1456L79.942 32.4051L85.649 32.3824L85.5705 11.1228Z"
                fill="#05004D"
            />
            <path
                d="M82.6567 1.19748C80.7262 1.20555 79.2129 2.76525 79.2197 4.85057C79.2274 6.89351 80.7524 8.39571 82.6829 8.38764C84.5717 8.37956 86.085 6.86425 86.0782 4.82232C86.0695 2.73599 84.5455 1.18941 82.6567 1.19748Z"
                fill="#05004D"
            />
            <path
                d="M88.0077 20.2711L88.0436 32.3774L93.7516 32.3542L93.7108 20.5243C93.7108 20.5243 93.4683 14.913 100.592 16.5625L100.57 11.1278C93.2772 9.27349 88.0077 12.8338 88.0077 20.2711Z"
                fill="#05004D"
            />
            <path
                d="M134.516 10.47C128.438 10.4962 123.723 15.1743 123.748 21.6098C123.771 28.0907 128.522 32.8646 134.598 32.8384C140.634 32.8132 145.391 28.0009 145.366 21.521C145.343 15.0855 140.552 10.4447 134.516 10.47ZM134.577 27.3351C131.578 27.3482 129.382 24.7817 129.371 21.5846C129.36 18.4329 131.538 15.9834 134.536 15.9713C137.492 15.9592 139.729 18.3925 139.741 21.5432C139.751 24.7403 137.533 27.323 134.577 27.3401V27.3351Z"
                fill="#05004D"
            />
            <path
                d="M116.676 11.1984V12.3193C115.255 11.2095 113.318 10.3792 110.87 10.3792C105.45 10.3792 101.072 14.9029 101.095 21.2941C101.115 26.8428 105.526 31.6611 110.946 31.6379C113.163 31.6289 115.009 30.9993 116.524 29.9279L116.533 31.3706C116.545 34.6998 114.58 36.4845 112.035 36.4946C109.324 36.5057 107.676 35.0479 107.423 32.8304L101.673 32.8546C102.104 38.2672 106.347 42.0221 112.053 41.9979C118.008 41.9727 122.223 37.9625 122.198 31.304L122.122 11.1762L116.676 11.1984ZM112.037 26.4816C108.577 26.4816 106.764 24.0735 106.764 21.0267C106.764 18.0143 108.704 15.5718 112.037 15.5718C115.058 15.5718 117.309 18.0143 117.309 21.0267C117.309 24.0392 115.156 26.4837 112.037 26.4837V26.4816Z"
                fill="#05004D"
            />
            <path
                d="M180.796 2.25378L175.399 2.27699L171.669 8.48552L167.919 2.30826L162.522 2.33147L168.733 12.4171L162.476 22.3382L167.668 22.316L171.722 16.1236L175.821 22.2817L181.015 22.2595L174.632 12.3918L180.796 2.25378Z"
                fill="#05004D"
            />
            <path
                d="M164.411 14.0282H159.852C159.261 16.6512 157.684 18.0445 155.262 18.0535C152.246 18.0535 150.131 15.6938 150.121 12.3141C150.11 8.93445 152.216 6.54345 155.272 6.53033C157.654 6.53033 159.234 7.90945 159.84 10.5174L159.851 10.5607H164.41L164.379 10.359C163.594 5.21882 160.02 1.89764 155.272 1.89764H155.229C152.604 1.90874 150.179 2.95998 148.402 4.85764C146.596 6.78558 145.609 9.44191 145.62 12.3373C145.641 18.2432 149.791 22.6973 155.263 22.6973H155.305C160.076 22.6772 163.643 19.3257 164.393 14.1593L164.411 14.0282Z"
                fill="#05004D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M248.872 2.09999H191.206C186.178 2.09999 182.101 6.33089 182.101 11.55C182.101 16.769 186.178 20.9999 191.206 20.9999H248.872C253.9 20.9999 257.977 16.769 257.977 11.55C257.977 6.33088 253.9 2.09999 248.872 2.09999ZM191.206 1.04999C185.619 1.04999 181.089 5.75099 181.089 11.55C181.089 17.3489 185.619 22.0499 191.206 22.0499H248.872C254.459 22.0499 258.988 17.3489 258.988 11.55C258.988 5.75099 254.459 1.04999 248.872 1.04999H191.206Z"
                fill="#05004D"
            />
            <path
                d="M191.179 17.896H193.85L195.987 9.71438L198.091 17.896H200.811L203.999 6.13599H201.361L199.435 14.6872L197.444 6.13599H194.482L192.507 14.704L190.581 6.13599H187.958L191.179 17.896Z"
                fill="#05004D"
            />
            <path
                d="M205.239 17.896H213.202V15.6112H207.796V12.8392H211.94V10.5544H207.796V8.40398H213.024V6.13599H205.239V17.896Z"
                fill="#05004D"
            />
            <path
                d="M222.169 17.896H224.807L220.518 6.13599H218.106L213.816 17.896H216.471L217.296 15.4768H221.343L222.169 17.896ZM218.073 13.2592L219.32 9.61358L220.566 13.2592H218.073Z"
                fill="#05004D"
            />
            <path
                d="M225.804 17.896H233.444V15.6112H228.362V6.13599H225.804V17.896Z"
                fill="#05004D"
            />
            <path
                d="M235.664 17.896H238.222V8.40398H241.54V6.13599H232.33V8.40398H235.664V17.896Z"
                fill="#05004D"
            />
            <path
                d="M249.586 6.13599V10.7056H245.086V6.13599H242.528V17.896H245.086V12.9736H249.586V17.896H252.159V6.13599H249.586Z"
                fill="#05004D"
            />
        </g>
        <defs>
            <clipPath id="clip0_144_5163">
                <rect width="260" height="42" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const SmallWealthLogo = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_34_4808)">
            <path
                d="M11.0226 2.57482C9.02634 -0.173583 5.22197 -0.788358 2.50249 1.22955C-0.216983 3.24023 -0.817294 7.10246 1.17892 9.86534L16.6134 31.3174C18.5951 34.073 22.4067 34.6733 25.1335 32.6626C27.8529 30.652 28.4532 26.7897 26.4715 24.0269L11.0226 2.57482Z"
                fill="#C9BA96"
            />
            <path
                d="M32.7784 16.7219C37.3241 16.7219 41.0091 12.9883 41.0091 8.38264C41.0091 3.777 37.3241 0.043396 32.7784 0.043396C28.2326 0.043396 24.5476 3.777 24.5476 8.38264C24.5476 12.9883 28.2326 16.7219 32.7784 16.7219Z"
                fill="#C9BA96"
            />
        </g>
        <defs>
            <clipPath id="clip0_34_4808">
                <rect width="42" height="42" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const DashboardIcon = ({ ...props }) => (
    <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Group 2220">
            <path
                id="Rectangle 317"
                d="M1 9.79626C1 8.93935 1.36644 8.12334 2.00691 7.55404L7.00691 3.10959C8.14357 2.09923 9.85643 2.09923 10.9931 3.10959L15.9931 7.55404C16.6336 8.12334 17 8.93935 17 9.79626V16C17 17.6569 15.6569 19 14 19H4C2.34315 19 1 17.6569 1 16V9.79626Z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <rect
                id="Rectangle 318"
                x="5"
                y="11"
                width="8"
                height="2"
                rx="1"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const NotificationIcon = ({ ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 16H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V16Z"
            stroke="#455472"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M16.7172 8.09117L17.847 16L6.15301 16L7.28284 8.09118C7.6182 5.74368 9.62867 4 12 4C14.3713 4 16.3818 5.74368 16.7172 8.09117Z"
            stroke="#455472"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M20 16C20 16.5523 19.5523 17 19 17L5 17C4.44772 17 4 16.5523 4 16V16C4 15.4477 4.44772 15 5 15L19 15C19.5523 15 20 15.4477 20 16V16Z"
            fill="#455472"
        />
    </svg>
);

export const BeneficiariesIcon = ({ ...props }) => (
    <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Group 2212">
            <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7C12 8.10457 11.1046 9 10 9ZM12.9055 9.74918C13.5839 9.03243 14 8.0648 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 8.0648 6.41606 9.03243 7.09447 9.74918C5.2491 10.7725 4 12.7404 4 15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15C6 12.7909 7.79086 11 10 11C12.2091 11 14 12.7909 14 15C14 15.5523 14.4477 16 15 16C15.5523 16 16 15.5523 16 15C16 12.7404 14.7509 10.7725 12.9055 9.74918Z"
                fill="currentColor"
            />
            <path
                id="Intersect"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.14306 5.80763C5.04957 6.18974 5 6.58908 5 7C5 7.37684 5.04169 7.74395 5.12071 8.09695C3.33457 8.4976 2 10.0929 2 12C2 12.5523 1.55228 13 1 13C0.447715 13 0 12.5523 0 12C0 9.74038 1.2491 7.7725 3.09447 6.74918C2.41606 6.03243 2 5.0648 2 4C2 1.79086 3.79086 0 6 0C7.49156 0 8.79243 0.816387 9.48017 2.0267C8.76261 2.10082 8.09007 2.32669 7.49521 2.67168C7.12887 2.25961 6.59475 2 6 2C4.89543 2 4 2.89543 4 4C4 4.79791 4.46725 5.48668 5.14306 5.80763ZM10.5198 2.0267C11.2076 0.816387 12.5084 0 14 0C16.2091 0 18 1.79086 18 4C18 5.0648 17.5839 6.03243 16.9055 6.74918C18.7509 7.7725 20 9.74038 20 12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12C18 10.0929 16.6654 8.4976 14.8793 8.09695C14.9583 7.74395 15 7.37684 15 7C15 6.58908 14.9504 6.18974 14.8569 5.80763C15.5328 5.48668 16 4.79791 16 4C16 2.89543 15.1046 2 14 2C13.4052 2 12.8711 2.25961 12.5048 2.67168C11.9099 2.32669 11.2374 2.10082 10.5198 2.0267Z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export const SendMoneyIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        {...props}
    >
        <path
            d="M12.2426 4.58578L9.41421 1.75735C8.63317 0.976305 7.36684 0.976305 6.58579 1.75735L3.75736 4.58578"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <rect x="7" y="1" width="2" height="6" rx="1" fill="currentColor" />
        <rect
            x="6"
            y="16"
            width="4"
            height="4"
            rx="2"
            transform="rotate(-90 6 16)"
            stroke="currentColor"
            strokeWidth="2"
        />
        <rect
            x="1"
            y="9"
            width="14"
            height="10"
            rx="3"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export const TransactionIcon = ({ ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.669 19.7567L16 19.1546L15.331 19.7567L14 20.9546L12.669 19.7567L12 19.1546L11.331 19.7567L10 20.9546L8.66896 19.7567L8 19.1546L7.33104 19.7567L6 20.9546L5.33103 20.3526C5.12032 20.1629 5 19.8928 5 19.6093V6C5 4.34315 6.34315 3 8 3H16C17.6569 3 19 4.34315 19 6V19.6093C19 19.8928 18.8797 20.1629 18.669 20.3526L18 20.9546L16.669 19.7567Z"
            stroke="currentColor"
            strokeWidth="2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.27552 8.21078C9.27535 8.21078 9.27568 8.21078 9.27552 8.21078C8.72323 8.21078 8.27502 8.6585 8.27502 9.21078C8.27502 9.76307 8.72274 10.2108 9.27502 10.2108L15.275 10.2108C15.5603 10.2108 15.8176 10.0914 15.9998 9.89978C16.0431 9.8542 16.0822 9.80453 16.1164 9.75141C16.2274 9.58852 16.2849 9.40012 16.289 9.21078C16.2946 8.94771 16.1971 8.68285 15.9963 8.4821C15.9962 8.482 15.9964 8.48219 15.9963 8.4821L14.5827 7.0685C14.1922 6.67798 13.5584 6.67736 13.1679 7.06788C12.8587 7.37704 12.7943 7.83826 12.9747 8.21078L9.27552 8.21078Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2887 13.775C15.2889 13.775 15.2885 13.775 15.2887 13.775C15.841 13.775 16.2892 13.3273 16.2892 12.775C16.2892 12.2227 15.8415 11.775 15.2892 11.775L9.28919 11.775C9.00395 11.775 8.7466 11.8944 8.56442 12.086C8.52107 12.1316 8.48198 12.1813 8.44778 12.2344C8.33681 12.3973 8.27929 12.5857 8.27523 12.775C8.26958 13.0381 8.36714 13.3029 8.56789 13.5037C8.56799 13.5038 8.56779 13.5036 8.56789 13.5037L9.98148 14.9173C10.372 15.3078 11.0058 15.3084 11.3963 14.9179C11.7055 14.6087 11.7699 14.1475 11.5896 13.775L15.2887 13.775Z"
            fill="currentColor"
        />
    </svg>
);

export const TutorialIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        {...props}
    >
        <path
            d="M1 3.51246C1 2.12487 2.12487 1 3.51246 1C4.99309 1 6.45339 1.34473 7.77771 2.00689L8.44721 2.34164C8.786 2.51103 9 2.8573 9 3.23607V17.382L8.67214 17.218C7.07011 16.417 5.30358 16 3.51246 16C2.12487 16 1 14.8751 1 13.4875V3.51246Z"
            stroke="#455472"
            strokeWidth="2"
        />
        <path
            d="M17 3.51246C17 2.12487 15.8751 1 14.4875 1C13.0069 1 11.5466 1.34473 10.2223 2.00689L9.55279 2.34164C9.214 2.51103 9 2.8573 9 3.23607V17.382L9.32786 17.218C10.9299 16.417 12.6964 16 14.4875 16C15.8751 16 17 14.8751 17 13.4875V3.51246Z"
            stroke="#455472"
            strokeWidth="2"
        />
    </svg>
);

export const FaqIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        {...props}
    >
        <circle cx="9" cy="9" r="8" stroke="#455472" strokeWidth="2" />
        <path
            d="M7.91091 11.0122V10.8608C7.91388 10.3411 7.95991 9.92687 8.049 9.61804C8.14105 9.30921 8.27468 9.05976 8.44989 8.86971C8.62509 8.67966 8.83593 8.50742 9.08241 8.35301C9.26652 8.23422 9.43133 8.11099 9.57684 7.9833C9.72235 7.8556 9.83816 7.71455 9.92428 7.56013C10.0104 7.40275 10.0535 7.22754 10.0535 7.03452C10.0535 6.82962 10.0045 6.64996 9.90646 6.49555C9.80846 6.34113 9.67632 6.22235 9.51002 6.1392C9.3467 6.05605 9.16555 6.01448 8.96659 6.01448C8.77357 6.01448 8.59094 6.05754 8.41871 6.14365C8.24647 6.2268 8.10542 6.35152 7.99555 6.51782C7.88567 6.68114 7.82628 6.88456 7.81737 7.12806H6C6.01485 6.53415 6.15739 6.04417 6.42762 5.65813C6.69785 5.26912 7.05568 4.97958 7.50111 4.78953C7.94655 4.59651 8.43801 4.5 8.9755 4.5C9.56644 4.5 10.0891 4.598 10.5434 4.79399C10.9978 4.98701 11.3541 5.26763 11.6125 5.63586C11.8708 6.00408 12 6.44803 12 6.96771C12 7.31514 11.9421 7.62398 11.8263 7.89421C11.7134 8.16147 11.5546 8.39904 11.3497 8.6069C11.1448 8.8118 10.9027 8.9974 10.6236 9.1637C10.389 9.30327 10.196 9.44877 10.0445 9.60022C9.89607 9.75167 9.78471 9.92687 9.71047 10.1258C9.6392 10.3248 9.60208 10.5698 9.59911 10.8608V11.0122H7.91091ZM8.79287 13.863C8.49592 13.863 8.24202 13.7591 8.03118 13.5512C7.82331 13.3404 7.72086 13.088 7.72383 12.794C7.72086 12.503 7.82331 12.2535 8.03118 12.0457C8.24202 11.8378 8.49592 11.7339 8.79287 11.7339C9.07498 11.7339 9.32294 11.8378 9.53675 12.0457C9.75056 12.2535 9.85895 12.503 9.86192 12.794C9.85895 12.99 9.80698 13.1696 9.70601 13.333C9.60802 13.4933 9.47884 13.6225 9.31849 13.7205C9.15813 13.8155 8.98292 13.863 8.79287 13.863Z"
            fill="#455472"
        />
    </svg>
);

export const AccountIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        {...props}
    >
        <rect
            x="3"
            y="1"
            width="10"
            height="10"
            rx="5"
            stroke="#455472"
            strokeWidth="2"
        />
        <path
            d="M15 18C15 14.134 11.866 11 8 11C4.13401 11 1 14.134 1 18"
            stroke="#455472"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export const PlusIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        {...props}
    >
        <rect
            x="0.333496"
            y="6.1665"
            width="13.3333"
            height="1.66667"
            rx="0.833333"
            fill="currentColor"
        />
        <rect
            x="7.8335"
            y="0.333252"
            width="13.3333"
            height="1.66667"
            rx="0.833333"
            transform="rotate(90 7.8335 0.333252)"
            fill="currentColor"
        />
    </svg>
);

export const SearchIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        {...props}
    >
        <circle cx="8" cy="8" r="7" stroke="#9199AA" strokeWidth="2" />
        <rect
            x="12.7071"
            y="13"
            width="1"
            height="7"
            rx="0.5"
            transform="rotate(-45 12.7071 13)"
            stroke="#9199AA"
        />
    </svg>
);

export const ArrowRightIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29289 16.2071C3.90237 15.8166 3.90237 15.1834 4.29289 14.7929L10.5858 8.5L4.29289 2.20711C3.90237 1.81658 3.90237 1.18342 4.29289 0.792893C4.68342 0.40237 5.31658 0.40237 5.70711 0.792893L12 7.08579C12.7811 7.86684 12.781 9.13317 12 9.91421L5.70711 16.2071C5.31658 16.5976 4.68342 16.5976 4.29289 16.2071Z"
            fill="#0038A8"
        />
    </svg>
);

export const EditIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.18896 7.98956L13.0355 4.14298C13.361 3.81754 13.361 3.2899 13.0355 2.96447C12.7101 2.63903 12.1825 2.63903 11.857 2.96447L8.01045 6.81105L7.4212 8.57881L9.18896 7.98956ZM14.2141 1.78596C13.2377 0.809645 11.6548 0.809645 10.6785 1.78596L6.83194 5.63254C6.64898 5.81549 6.51114 6.03854 6.42931 6.284L5.31301 9.63291C5.09586 10.2844 5.71564 10.9042 6.3671 10.687L9.71601 9.5707C9.96147 9.48888 10.1845 9.35103 10.3675 9.16807L14.2141 5.32149C15.1904 4.34518 15.1904 2.76227 14.2141 1.78596Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.45268 4.83329H3.66671C2.74623 4.83329 2.00004 5.57948 2.00004 6.49996V13.1666C2.00004 14.0871 2.74623 14.8333 3.66671 14.8333H10.3334C11.2538 14.8333 12 14.0871 12 13.1666V8.71402L13.6667 7.04736V13.1666C13.6667 15.0076 12.1743 16.5 10.3334 16.5H3.66671C1.82576 16.5 0.333374 15.0076 0.333374 13.1666V6.49996C0.333374 4.65901 1.82576 3.16663 3.66671 3.16663H8.11935L6.45268 4.83329Z"
            fill="currentColor"
        />
    </svg>
);

export const DeleteIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.00004 4.83329V5.66663H12V4.83329H2.00004ZM1.16671 3.16663C0.70647 3.16663 0.333374 3.53972 0.333374 3.99996V6.49996C0.333374 6.9602 0.70647 7.33329 1.16671 7.33329H12.8334C13.2936 7.33329 13.6667 6.9602 13.6667 6.49996V3.99996C13.6667 3.53972 13.2936 3.16663 12.8334 3.16663H1.16671Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33341 2.33329V3.16663H8.66675V2.33329H5.33341ZM4.50008 0.666626C4.03984 0.666626 3.66675 1.03972 3.66675 1.49996V3.99996C3.66675 4.4602 4.03984 4.83329 4.50008 4.83329H9.50008C9.96032 4.83329 10.3334 4.4602 10.3334 3.99996V1.49996C10.3334 1.03972 9.96032 0.666626 9.50008 0.666626H4.50008Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.97807 7.33329L3.67252 15.6666H10.3276L11.0221 7.33329H2.97807ZM2.0724 5.66663C1.58487 5.66663 1.20146 6.08331 1.24195 6.56916L2.07528 16.5692C2.11128 17.0011 2.47233 17.3333 2.90574 17.3333H11.0944C11.5278 17.3333 11.8889 17.0011 11.9249 16.5692L12.7582 6.56916C12.7987 6.08331 12.4153 5.66663 11.9277 5.66663H2.0724Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.16667 6.49996C6.16667 6.03972 5.79357 5.66663 5.33333 5.66663C4.8731 5.66663 4.5 6.03972 4.5 6.49996V11.5C4.5 11.9602 4.8731 12.3333 5.33333 12.3333C5.79357 12.3333 6.16667 11.9602 6.16667 11.5V6.49996Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.50004 6.49996V9.83329C9.50004 10.2935 9.12694 10.6666 8.66671 10.6666C8.20647 10.6666 7.83337 10.2935 7.83337 9.83329V6.49996C7.83337 6.03972 8.20647 5.66663 8.66671 5.66663C9.12694 5.66663 9.50004 6.03972 9.50004 6.49996Z"
            fill="currentColor"
        />
    </svg>
);

export const SignoutIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        {...props}
    >
        <path
            d="M10 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H10"
            stroke="#455472"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <rect x="7.5" y="7.5" width="10" height="1" rx="0.5" stroke="#455472" />
        <path
            d="M14 12.4853L16.8285 9.65689C17.6095 8.87584 17.6095 7.60951 16.8285 6.82846L14 4.00004"
            stroke="#455472"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export const BackIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            d="M14.5605 6.00043L10.0613 10.4996C9.23247 11.3285 9.23247 12.6724 10.0613 13.5012L14.5605 18.0004"
            stroke="#05004D"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export const SingleEftIcon = ({ ...props }) => (
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Single_EFT">
            <g id="Group 2225">
                <path
                    id="Rectangle 379 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5 40L32.5 40C34.7091 40 36.5 38.2091 36.5 36L36.5 17.6569L26.8431 8L16.5 8C14.2909 8 12.5 9.79086 12.5 12L12.5 36C12.5 38.2091 14.2909 40 16.5 40ZM32.5 44C36.9183 44 40.5 40.4183 40.5 36L40.5 17.6569C40.5 16.596 40.0786 15.5786 39.3284 14.8284L29.6716 5.17158C28.9214 4.42143 27.904 4 26.8431 4L16.5 4C12.0817 4 8.5 7.58172 8.5 12L8.5 36C8.5 40.4183 12.0817 44 16.5 44L32.5 44Z"
                    fill="#455472"
                />
                <path
                    id="Rectangle 380 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.5 40L32.5 40C34.7091 40 36.5 38.2091 36.5 36L36.5 20L30.5 20C27.1863 20 24.5 17.3137 24.5 14L24.5 8L16.5 8C14.2909 8 12.5 9.79086 12.5 12L12.5 36C12.5 38.2091 14.2909 40 16.5 40ZM32.5 44C36.9183 44 40.5 40.4183 40.5 36L40.5 20C40.5 17.7909 38.7091 16 36.5 16L30.5 16C29.3954 16 28.5 15.1046 28.5 14L28.5 8C28.5 5.79086 26.7091 4 24.5 4L16.5 4C12.0817 4 8.5 7.58172 8.5 12L8.5 36C8.5 40.4183 12.0817 44 16.5 44L32.5 44Z"
                    fill="#455472"
                />
                <path
                    id="Rectangle 327 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.6716 22C23.2337 20.4379 25.7663 20.4379 27.3284 22L31.9142 26.5858C32.6953 27.3668 32.6953 28.6332 31.9142 29.4142C31.1332 30.1953 29.8668 30.1953 29.0858 29.4142L24.5 24.8284L19.9142 29.4142C19.1332 30.1953 17.8668 30.1953 17.0858 29.4142C16.3047 28.6332 16.3047 27.3668 17.0858 26.5858L21.6716 22Z"
                    fill="#455472"
                />
                <rect
                    id="Rectangle 328"
                    x="22.5"
                    y="22"
                    width="4"
                    height="16"
                    rx="2"
                    fill="#455472"
                />
            </g>
        </g>
    </svg>
);

export const BatchEftIcon = ({ ...props }) => (
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Batch_EFT">
            <g id="Group 2229">
                <g id="Group 2226">
                    <path
                        id="Rectangle 327 (Stroke)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.6716 20.0001C23.2337 18.438 25.7663 18.438 27.3284 20.0001L31.9142 24.5858C32.6953 25.3669 32.6953 26.6332 31.9142 27.4143C31.1332 28.1953 29.8668 28.1953 29.0858 27.4143L24.5 22.8285L19.9142 27.4143C19.1332 28.1953 17.8668 28.1953 17.0858 27.4143C16.3047 26.6332 16.3047 25.3669 17.0858 24.5858L21.6716 20.0001Z"
                        fill="#455472"
                    />
                    <rect
                        id="Rectangle 328"
                        x="22.5"
                        y="20"
                        width="4"
                        height="16"
                        rx="2"
                        fill="#455472"
                    />
                </g>
                <path
                    id="Rectangle 384 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.5 34V14C40.5 11.7909 38.7091 10 36.5 10H30.5L26.9 14.8C25.3892 16.8144 23.0181 18 20.5 18H8.5V34C8.5 36.2091 10.2909 38 12.5 38H36.5C38.7091 38 40.5 36.2091 40.5 34ZM8.5 14C6.29086 14 4.5 15.7909 4.5 18V34C4.5 38.4183 8.08172 42 12.5 42H36.5C40.9183 42 44.5 38.4183 44.5 34V14C44.5 9.58172 40.9183 6 36.5 6H30.5C29.241 6 28.0554 6.59278 27.3 7.6L23.7 12.4C22.9446 13.4072 21.759 14 20.5 14H8.5Z"
                    fill="#455472"
                />
                <path
                    id="Subtract"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.85351 14.3535C7.46566 14.9811 6.5 16.3778 6.5 18V32C6.5 36.4183 10.0817 40 14.5 40H12.5C8.08172 40 4.5 36.4183 4.5 32V16C4.5 11.5817 8.08172 8 12.5 8H27L24 12H12.5C10.8778 12 9.48114 12.9657 8.85351 14.3535Z"
                    fill="#455472"
                />
            </g>
        </g>
    </svg>
);

export const WireIcon = ({ ...props }) => (
    <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Wire">
            <g id="Group 2230">
                <path
                    id="Rectangle 386 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5 12C11.1863 12 8.5 14.6863 8.5 18V30C8.5 33.3137 11.1863 36 14.5 36C15.6046 36 16.5 36.8954 16.5 38C16.5 39.1046 15.6046 40 14.5 40C8.97715 40 4.5 35.5228 4.5 30V18C4.5 12.4772 8.97715 8 14.5 8H28.5C29.6046 8 30.5 8.89543 30.5 10C30.5 11.1046 29.6046 12 28.5 12H14.5ZM33.5 10C33.5 8.89543 34.3954 8 35.5 8C40.4706 8 44.5 12.0294 44.5 17V30C44.5 35.5228 40.0228 40 34.5 40H20.5C19.3954 40 18.5 39.1046 18.5 38C18.5 36.8954 19.3954 36 20.5 36H34.5C37.8137 36 40.5 33.3137 40.5 30V17C40.5 14.2386 38.2614 12 35.5 12C34.3954 12 33.5 11.1046 33.5 10Z"
                    fill="#455472"
                />
                <path
                    id="$"
                    d="M23.9963 34V14H25.2111V34H23.9963ZM27.2185 20.6016C27.1593 19.9714 26.9049 19.4818 26.4556 19.1328C26.0062 18.7839 25.3963 18.6094 24.6259 18.6094C24.1025 18.6094 23.6605 18.6875 23.3 18.8438C22.9395 18.9948 22.663 19.2057 22.4704 19.4766C22.2827 19.7474 22.1889 20.0547 22.1889 20.3984C22.179 20.6849 22.2358 20.9349 22.3593 21.1484C22.4877 21.362 22.663 21.5469 22.8852 21.7031C23.1074 21.8542 23.3642 21.987 23.6556 22.1016C23.9469 22.2109 24.258 22.3047 24.5889 22.3828L25.9519 22.7266C26.6136 22.8828 27.221 23.0911 27.7741 23.3516C28.3272 23.612 28.8062 23.9323 29.2111 24.3125C29.616 24.6927 29.9296 25.1406 30.1519 25.6562C30.379 26.1719 30.4951 26.763 30.5 27.4297C30.4951 28.4089 30.258 29.2578 29.7889 29.9766C29.3247 30.6901 28.6531 31.2448 27.7741 31.6406C26.9 32.0312 25.8457 32.2266 24.6111 32.2266C23.3864 32.2266 22.3198 32.0286 21.4111 31.6328C20.5074 31.237 19.8012 30.651 19.2926 29.875C18.7889 29.0937 18.5247 28.1276 18.5 26.9766H21.6037C21.6383 27.513 21.784 27.9609 22.0407 28.3203C22.3025 28.6745 22.6506 28.9427 23.0852 29.125C23.5247 29.3021 24.021 29.3906 24.5741 29.3906C25.1173 29.3906 25.5889 29.3073 25.9889 29.1406C26.3938 28.974 26.7074 28.7422 26.9296 28.4453C27.1519 28.1484 27.263 27.8073 27.263 27.4219C27.263 27.0625 27.1617 26.7604 26.9593 26.5156C26.7617 26.2708 26.4704 26.0625 26.0852 25.8906C25.7049 25.7188 25.2383 25.5625 24.6852 25.4219L23.0333 24.9844C21.7543 24.6562 20.7444 24.1432 20.0037 23.4453C19.263 22.7474 18.8951 21.8073 18.9 20.625C18.8951 19.6562 19.1395 18.8099 19.6333 18.0859C20.1321 17.362 20.816 16.7969 21.6852 16.3906C22.5543 15.9844 23.542 15.7813 24.6481 15.7813C25.7741 15.7813 26.7568 15.9844 27.5963 16.3906C28.4407 16.7969 29.0975 17.362 29.5667 18.0859C30.0358 18.8099 30.2778 19.6484 30.2926 20.6016H27.2185Z"
                    fill="#455472"
                />
                <path
                    id="Rectangle 387 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.1779 4.60678L30.1295 8.55839C30.9106 9.33943 30.9106 10.6058 30.1295 11.3868C29.3485 12.1679 28.0822 12.1679 27.3011 11.3868L23.3495 7.43521C22.5685 6.65416 22.5685 5.38783 23.3495 4.60678C24.1306 3.82573 25.3969 3.82573 26.1779 4.60678Z"
                    fill="#455472"
                />
                <path
                    id="Rectangle 388 (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.7426 36.5858L25.6942 40.5374C26.4753 41.3184 26.4753 42.5848 25.6942 43.3658C24.9132 44.1469 23.6469 44.1469 22.8658 43.3658L18.9142 39.4142C18.1332 38.6332 18.1332 37.3668 18.9142 36.5858C19.6953 35.8047 20.9616 35.8047 21.7426 36.5858Z"
                    fill="#455472"
                />
            </g>
        </g>
    </svg>
);

export const WalletTransIcon = ({ ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5858 2.58579C11.3668 1.80474 12.6332 1.80474 13.4142 2.58579L15.7071 4.87868C16.0976 5.2692 16.0976 5.90237 15.7071 6.29289C15.3166 6.68342 14.6834 6.68342 14.2929 6.29289L12 4L9.70711 6.29289C9.31658 6.68342 8.68342 6.68342 8.29289 6.29289C7.90237 5.90237 7.90237 5.2692 8.29289 4.87868L10.5858 2.58579Z"
            fill="#455472"
        />
        <path
            d="M11 3.17157C11 2.61929 11.4477 2.17157 12 2.17157C12.5523 2.17157 13 2.61929 13 3.17157V11.1716C13 11.7239 12.5523 12.1716 12 12.1716C11.4477 12.1716 11 11.7239 11 11.1716V3.17157Z"
            fill="#455472"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 10H7.33333C6.71205 10 6.19002 10.4249 6.04201 11H16.7324C16.3866 10.4022 15.7403 10 15 10ZM18.874 11C18.4299 9.27477 16.8638 8 15 8H7.33333C5.49238 8 4 9.49238 4 11.3333C4 12.2538 4.74619 13 5.66667 13H19V12C19 11.6547 18.9562 11.3196 18.874 11Z"
            fill="#455472"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 13H6.2847C6.18946 13 6.09447 12.9958 6 12.9873V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18L18 15C18 13.8954 17.1046 13 16 13ZM16 11H6.2847C6.09747 11 5.91282 10.9564 5.74536 10.8727C4.94348 10.4717 4 11.0548 4 11.9514V18C4 20.2091 5.79086 22 8 22H16C18.2091 22 20 20.2091 20 18L20 15C20 12.7909 18.2091 11 16 11Z"
            fill="#455472"
        />
    </svg>
);

export const DownloadIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        {...props}
    >
        <path
            d="M1.16658 11.3334V11.3334C1.16658 13.1743 2.65897 14.6667 4.49992 14.6667L9.49992 14.6667C11.3409 14.6667 12.8333 13.1743 12.8333 11.3334V11.3334"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <mask id="path-2-inside-1_638_28597" fill="white">
            <path d="M7.83325 9.66667C7.83325 10.1269 7.46016 10.5 6.99992 10.5C6.53968 10.5 6.16658 10.1269 6.16658 9.66667L6.16659 1.33333C6.16659 0.873095 6.53968 0.5 6.99992 0.5C7.46016 0.5 7.83325 0.873096 7.83325 1.33333L7.83325 9.66667Z" />
        </mask>
        <path
            d="M8.16658 9.66667L8.16659 1.33333L4.16659 1.33333L4.16659 9.66667L8.16658 9.66667ZM5.83325 1.33333L5.83325 9.66667L9.83325 9.66667L9.83325 1.33333L5.83325 1.33333ZM6.99992 2.5C6.35559 2.5 5.83325 1.97766 5.83325 1.33333L9.83325 1.33333C9.83325 -0.231472 8.56473 -1.5 6.99992 -1.5L6.99992 2.5ZM8.16659 1.33333C8.16659 1.97766 7.64425 2.5 6.99992 2.5L6.99992 -1.5C5.43511 -1.5 4.16659 -0.231473 4.16659 1.33333L8.16659 1.33333ZM6.99992 8.5C7.64425 8.5 8.16658 9.02234 8.16658 9.66667L4.16659 9.66667C4.16658 11.2315 5.43511 12.5 6.99992 12.5L6.99992 8.5ZM6.99992 12.5C8.56473 12.5 9.83325 11.2315 9.83325 9.66667L5.83325 9.66667C5.83325 9.02233 6.35559 8.5 6.99992 8.5L6.99992 12.5Z"
            fill="currentColor"
            mask="url(#path-2-inside-1_638_28597)"
        />
        <path
            d="M2.28595 6.33332L6.29289 10.3403C6.68342 10.7308 7.31658 10.7308 7.70711 10.3403L11.714 6.33332"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export const UploadIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="51"
        height="50"
        viewBox="0 0 51 50"
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_615_40584)">
            <path
                d="M38.3321 3.78572C32.706 0.448804 25.9897 -0.530989 19.6449 1.05958C13.3 2.65014 7.84011 6.68232 4.45349 12.2786C1.10919 17.9007 0.12485 24.6175 1.71535 30.9628C3.30586 37.3081 7.34203 42.7665 12.9428 46.1464C13.7071 46.5964 14.4963 46.9929 15.3142 47.3321C15.5428 47.45 15.7928 47.5107 16.0463 47.5179C16.3743 47.5194 16.6956 47.4249 16.9704 47.2459C17.2452 47.0669 17.4616 46.8113 17.5928 46.5107C17.6846 46.3101 17.7322 46.0921 17.7322 45.8714C17.7322 45.6508 17.6846 45.4328 17.5928 45.2322C17.5185 45.0088 17.3972 44.8039 17.237 44.6314C17.0768 44.4589 16.8815 44.3228 16.6642 44.2321C15.9866 43.8838 15.3174 43.5194 14.6571 43.1393C9.8372 40.2335 6.36773 35.5334 5.01063 30.0714C4.32205 27.3857 4.1768 24.5895 4.58341 21.8469C4.99002 19.1042 5.9403 16.4704 7.37849 14.1V14.0071C9.28786 10.9019 11.9592 8.33617 15.1389 6.55351C18.3185 4.77084 21.9011 3.83039 25.5463 3.82143C29.3748 3.79053 33.1373 4.8189 36.4178 6.79286C40.7219 9.43714 43.9581 13.5119 45.5593 18.3029C47.1604 23.0939 47.0244 28.2957 45.1749 32.9964C44.7463 33.9964 44.2606 34.975 43.7213 35.925C41.516 39.6174 38.2332 42.5462 34.3142 44.3179C33.9038 44.5129 33.5872 44.8622 33.4333 45.2897C33.2794 45.7172 33.3008 46.1882 33.4928 46.6C33.6271 46.8998 33.8452 47.1544 34.1208 47.3331C34.3964 47.5117 34.7179 47.6069 35.0463 47.6071C35.2993 47.5882 35.5471 47.5254 35.7785 47.4214C40.3058 45.344 44.0929 41.9368 46.6356 37.6536C48.3333 34.8896 49.4656 31.8162 49.967 28.6115C50.4684 25.4068 50.3289 22.1344 49.5566 18.984C48.7842 15.8336 47.3945 12.8677 45.4677 10.2582C43.541 7.64878 41.1156 5.44757 38.3321 3.78215V3.78572Z"
                fill="#C9BA96"
            />
            <path
                d="M25.6392 45.6928C25.3019 45.6997 24.9725 45.7958 24.6845 45.9715C24.3965 46.1472 24.1603 46.3961 23.9999 46.6928C23.8187 46.9799 23.7226 47.3123 23.7226 47.6518C23.7226 47.9912 23.8187 48.3237 23.9999 48.6107C24.1781 48.8861 24.4203 49.1144 24.7057 49.276C24.9911 49.4377 25.3114 49.528 25.6392 49.5393C25.978 49.529 26.3081 49.4304 26.5969 49.2531C26.8858 49.0758 27.1232 48.826 27.2856 48.5286C27.6428 47.9393 27.6428 47.2 27.2856 46.6107C27.1042 46.3376 26.8601 46.1118 26.5737 45.9521C26.2873 45.7924 25.9669 45.7035 25.6392 45.6928ZM33.1285 20.4893L27.1928 13.4643C27.0486 13.1387 26.7891 12.8779 26.4642 12.7321C26.1929 12.5414 25.8671 12.4437 25.5356 12.4536C25.207 12.4562 24.8861 12.5529 24.6106 12.7321C24.3321 12.9178 24.0571 13.0893 23.9713 13.3714L17.9428 20.5857C17.7459 20.8225 17.6164 21.1081 17.568 21.4122C17.5197 21.7164 17.5541 22.028 17.6678 22.3143C17.8892 22.9036 18.4142 23.325 19.0356 23.4178H19.4071C19.6668 23.4064 19.9216 23.3437 20.157 23.2334C20.3923 23.123 20.6036 22.9673 20.7785 22.775L23.7856 19.075V35.7393C23.8071 36.3821 24.1571 36.9678 24.7142 37.2857C24.9837 37.4773 25.3087 37.5752 25.6392 37.5643C26.1262 37.5525 26.5899 37.3538 26.9343 37.0094C27.2787 36.6649 27.4774 36.2012 27.4892 35.7143V19.0464L30.5106 22.6107C30.8606 23.0036 31.3535 23.2321 31.8785 23.25H32.1499C32.4629 23.1922 32.7547 23.0518 32.9952 22.8433C33.2357 22.6348 33.4161 22.3659 33.5178 22.0643C33.5993 21.7915 33.6061 21.5018 33.5377 21.2254C33.4692 20.949 33.3279 20.6961 33.1285 20.4928V20.4893Z"
                fill="#C9BA96"
            />
        </g>
        <defs>
            <clipPath id="clip0_615_40584">
                <rect
                    width="50"
                    height="50"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);

export const ClockIcon = ({ ...props }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Time">
            <g id="Group 2232">
                <circle
                    id="Ellipse 116"
                    cx="10"
                    cy="10"
                    r="6.5"
                    stroke="#9199AA"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <rect
                    id="Rectangle 389"
                    x="9.1665"
                    y="6.66699"
                    width="1.66667"
                    height="4.16667"
                    rx="0.833333"
                    fill="#9199AA"
                />
                <rect
                    id="Rectangle 390"
                    x="13.5835"
                    y="12.5"
                    width="1.66667"
                    height="5"
                    rx="0.833333"
                    transform="rotate(135 13.5835 12.5)"
                    fill="#9199AA"
                />
            </g>
        </g>
    </svg>
);

export const FileIcon = ({ ...props }) => (
    <svg
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Group 2225">
            <path
                id="Rectangle 379 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 18L12.5 18C13.6046 18 14.5 17.1046 14.5 16L14.5 6.82843L9.67157 2L4.5 2C3.39543 2 2.5 2.89543 2.5 4L2.5 16C2.5 17.1046 3.39543 18 4.5 18ZM12.5 20C14.7091 20 16.5 18.2091 16.5 16L16.5 6.82843C16.5 6.298 16.2893 5.78929 15.9142 5.41422L11.0858 0.585788C10.7107 0.210716 10.202 1.35676e-06 9.67157 1.31039e-06L4.5 8.58275e-07C2.29086 6.65146e-07 0.5 1.79086 0.499999 4L0.499998 16C0.499998 18.2091 2.29086 20 4.5 20L12.5 20Z"
                fill="#05004D"
            />
            <path
                id="Rectangle 380 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 18L12.5 18C13.6046 18 14.5 17.1046 14.5 16L14.5 8L11.5 8C9.84315 8 8.5 6.65686 8.5 5L8.5 2L4.5 2C3.39543 2 2.5 2.89543 2.5 4L2.5 16C2.5 17.1046 3.39543 18 4.5 18ZM12.5 20C14.7091 20 16.5 18.2091 16.5 16L16.5 8C16.5 6.89543 15.6046 6 14.5 6L11.5 6C10.9477 6 10.5 5.55229 10.5 5L10.5 2C10.5 0.895431 9.60457 1.30453e-06 8.5 1.20797e-06L4.5 8.58275e-07C2.29086 6.65146e-07 0.5 1.79086 0.499999 4L0.499998 16C0.499998 18.2091 2.29086 20 4.5 20L12.5 20Z"
                fill="#05004D"
            />
        </g>
    </svg>
);

export const NoticeIcon = ({ ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Notice">
            <g id="Group 2216">
                <circle
                    id="Ellipse 114"
                    cx="12"
                    cy="12"
                    r="8"
                    stroke="#455472"
                    strokeWidth="2"
                />
                <path
                    id="!"
                    d="M12.925 8L12.7625 13.9748H11.2376L11.0709 8H12.925ZM12 16.6414C11.725 16.6414 11.4889 16.5442 11.2917 16.3498C11.0945 16.1525 10.9973 15.9164 11.0001 15.6414C10.9973 15.3692 11.0945 15.1359 11.2917 14.9415C11.4889 14.747 11.725 14.6498 12 14.6498C12.2639 14.6498 12.4958 14.747 12.6958 14.9415C12.8958 15.1359 12.9972 15.3692 13 15.6414C12.9972 15.8248 12.9486 15.9928 12.8542 16.1456C12.7625 16.2956 12.6417 16.4164 12.4917 16.5081C12.3417 16.597 12.1778 16.6414 12 16.6414Z"
                    fill="#455472"
                />
            </g>
        </g>
    </svg>
);

export const EmptyNotificationIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        {...props}
    >
        <circle cx="60" cy="92" r="20" fill="#455472" />
        <path
            d="M20 48C20 25.9086 37.9086 8 60 8C82.0914 8 100 25.9086 100 48V49.8778C100 61.8808 101.935 73.8052 105.731 85.1922L108 92H12L14.2693 85.1922C18.0649 73.8052 20 61.8808 20 49.8778V48Z"
            fill="#9199AA"
        />
        <circle cx="94" cy="22" r="14" fill="#CED2DB" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.127 13.8431C101.003 12.719 99.1806 12.719 98.0564 13.8431L93.9853 17.9142L89.9142 13.8431C88.79 12.719 86.9673 12.719 85.8431 13.8431C84.719 14.9673 84.719 16.79 85.8431 17.9142L89.9142 21.9853L85.8432 26.0563C84.719 27.1805 84.719 29.0032 85.8432 30.1274C86.9674 31.2516 88.79 31.2516 89.9142 30.1274L93.9853 26.0564L98.0563 30.1274C99.1805 31.2516 101.003 31.2516 102.127 30.1274C103.252 29.0032 103.252 27.1805 102.127 26.0564L98.0564 21.9853L102.127 17.9142C103.252 16.79 103.252 14.9673 102.127 13.8431Z"
            fill="#05004D"
        />
    </svg>
);

export const NoBeneficiaryIcon = ({ ...props }) => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10 36C10 27.1634 17.1634 20 26 20C47.3333 20 68.6667 20 90 20C98.8366 20 106 27.1634 106 36V84C106 92.8366 98.8366 100 90 100H26C17.1634 100 10 92.8366 10 84V36Z"
            fill="#455472"
        />
        <path
            d="M10 52C10 43.1634 17.1634 36 26 36H50.9792C54.3824 36 57.2174 38.6086 57.5 42C57.7826 45.3914 60.6176 48 64.0208 48H90C98.8366 48 106 55.1634 106 64V84C106 92.8366 98.8366 100 90 100H26C17.1634 100 10 92.8366 10 84V52Z"
            fill="#9199AA"
        />
        <circle
            cx="80"
            cy="72"
            r="17"
            fill="white"
            stroke="#CED2DB"
            strokeWidth="6"
        />
        <rect
            x="88.7867"
            y="84.8579"
            width="6"
            height="24"
            rx="3"
            transform="rotate(-45 88.7867 84.8579)"
            fill="#CED2DB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.6422 64.3578C87.518 63.2336 85.6953 63.2336 84.5711 64.3578L80.5001 68.4289L76.429 64.3578C75.3048 63.2336 73.4821 63.2336 72.3579 64.3578C71.2337 65.482 71.2337 67.3047 72.3579 68.4289L76.429 72.4999L72.3579 76.571C71.2337 77.6952 71.2337 79.5179 72.3579 80.6421C73.4821 81.7663 75.3048 81.7663 76.429 80.6421L80.5001 76.571L84.5711 80.6421C85.6953 81.7663 87.518 81.7663 88.6422 80.6421C89.7664 79.5179 89.7664 77.6952 88.6422 76.571L84.5711 72.4999L88.6422 68.4289C89.7664 67.3047 89.7664 65.482 88.6422 64.3578Z"
            fill="#05004D"
        />
    </svg>
);

export const SuccessIcon = ({ ...props }) => (
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20 60C20 37.9086 37.9086 20 60 20C82.0914 20 100 37.9086 100 60C100 82.0914 82.0914 100 60 100C37.9086 100 20 82.0914 20 60Z"
            fill="#05004D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.5978 43.0294C82.0357 41.4673 79.5031 41.4673 77.941 43.0294L52.2278 68.7426L43.4852 60C41.9231 58.4379 39.3904 58.4379 37.8283 60C36.2662 61.5621 36.2662 64.0947 37.8283 65.6568L49.142 76.9705C49.9886 77.8171 51.1203 78.2049 52.2281 78.1338C53.3357 78.2047 54.4671 77.8169 55.3135 76.9705L69.4557 62.8283L83.5978 48.6862C85.1599 47.1241 85.1599 44.5915 83.5978 43.0294Z"
            fill="white"
        />
    </svg>
);

export const FailedIcon = ({ ...props }) => (
    <svg
        width="120"
        height="121"
        viewBox="0 0 120 121"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20 60.5C20 38.4086 37.9086 20.5 60 20.5C82.0914 20.5 100 38.4086 100 60.5C100 82.5914 82.0914 100.5 60 100.5C37.9086 100.5 20 82.5914 20 60.5Z"
            fill="#FFF3F3"
        />
        <rect
            x="74.1421"
            y="40.7012"
            width="8"
            height="48"
            rx="4"
            transform="rotate(45 74.1421 40.7012)"
            fill="#E50000"
        />
        <rect
            x="79.7988"
            y="74.6421"
            width="8"
            height="48"
            rx="4"
            transform="rotate(135 79.7988 74.6421)"
            fill="#E50000"
        />
    </svg>
);
