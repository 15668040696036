const common = {
    test: "测试",
    //get code
    getCode: '获取代码',
    resend: '重新发送（{{state}}s）',
    myAcc:'我的账号',
    logout:'登出',

    // upload component
    uploader1:"拖动文件，或",
    uploader2:"点击上传",
    uploaderComment:"仅支持 png、jpg、jpeg 和 pdf 格式。文件最大为 15MB",
    FileTypeLimit: `只能上传 JPG/PNG/PDF文件！`,
    FileSizeLimit: `文件大小必须小于15MB！`,
    
    // not found
    notFoundH:"页面未找到",
    notFoundC:"抱歉，我们找不到您要查找的页面。请确保您的URL正确。",

    //header
    OTCDirect:'OTC Direct',
    ChatBroker:'在线人工服务',
    Learn:'学习',
    Earn$200:'奖励200+',
    welcome:'欢迎回来',

    // rule
    plsEnter:"请输入",

    delete:'删除',
};
export default common;
