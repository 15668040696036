import React, { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { Spin } from "antd";

import Layout from "components/Layout";
const Login = lazy(() => import("../pages/login"));
const Activation = lazy(() => import("../pages/login/activation"));
const ForgotPassword = lazy(() => import("../pages/login/forgot-password"));
const ResetPassword = lazy(() => import("../pages/login/reset-password"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Notification = lazy(() => import("../pages/notification"));
const Beneficiaries = lazy(() => import("../pages/beneficiaries"));
const BeneficiaryInfo = lazy(() => import("../pages/beneficiaries/beneficiary-info"));
const AddBeneficiary = lazy(() => import("../pages/beneficiaries/add-beneficiary"));
const SendMoney = lazy(() => import("../pages/send-money"));
const SingleEft = lazy(() => import("../pages/send-money/single-eft"));
const SingEftReview = lazy(() => import("../pages/send-money/single-eft/review"));
const BatchEft = lazy(() => import("../pages/send-money/batch-eft"));
const Wire = lazy(() => import("../pages/send-money/wire"));
const WireReview = lazy(() => import("../pages/send-money/wire/review"));
const WalletTransfer = lazy(() => import("../pages/send-money/wallet-transfer"));
const WalletTransferReview = lazy(() => import("../pages/send-money/wallet-transfer/review"));
const Transaction = lazy(() => import("../pages/transaction"));
const TransactionDetail = lazy(() => import("../pages/transaction/components/transactionDetail"));
const Account = lazy(() => import("../pages/account"));
const AddUser = lazy(() => import("../pages/account/components/addUser.js"));
const Test = lazy(() => import("../pages/test"));
const NoMatchPage = lazy(() => import("../pages/errorPage.js"));

const Loading = () => {
    return (
        <section
            style={{
                height: "calc(100vh - 312px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Spin size="large" />
        </section>
    );
};

const RoutesComponents = () => {
    const uLocation = useLocation();
    const pathsWithLayoutHidden = ["/login", "/404", "/activation", "/forgot-password", "/reset-password"];
    const layoutIsHidden = pathsWithLayoutHidden.some((path) => uLocation.pathname.includes(path));
    return (
        <>
            <main>
                <Layout isHidden={layoutIsHidden}>
                    <Suspense fallback={<Loading />}>
                        <Routes>
                            <Route path={"/"}>
                                <Route index element={<Dashboard />}></Route>
                                <Route path={"/test"} element={<Test />}></Route>
                                <Route path={"/login"} element={<Login />}></Route>
                                <Route path={"/activation"} element={<Activation />}></Route>
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path={"/dashboard"} element={<Dashboard />}></Route>
                                <Route path={"/notification"} element={<Notification />}></Route>
                                <Route path="/beneficiaries">
                                    <Route
                                        path=""
                                        index
                                        element={<Beneficiaries />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<BeneficiaryInfo />}
                                    />
                                    <Route
                                        path="add"
                                        element={<AddBeneficiary />}
                                    />
                                </Route>
                                <Route path="/send-money">
                                    <Route path="/send-money" index element={<SendMoney />} />
                                    <Route path="/send-money/single-eft" element={<SingleEft />} />
                                    <Route path="/send-money/single-eft/review" element={<SingEftReview />} />
                                    <Route path="/send-money/batch-eft" element={<BatchEft />} />
                                    <Route path="/send-money/wire" element={<Wire />} />
                                    <Route path="/send-money/wire/review" element={<WireReview />} />
                                    <Route path="/send-money/wallet-transfer" element={<WalletTransfer />} />
                                    <Route path="/send-money/wallet-transfer/review" element={<WalletTransferReview />} />
                                </Route>
                                <Route path={"/transaction"}>
                                    <Route index element={<Transaction />}></Route>
                                    <Route path=":type/:id" element={<TransactionDetail/>}></Route>
                                </Route>
                                <Route path={"/account"}>
                                    <Route index element={<Account />}></Route>
                                    <Route path="rolesAndPermission/addUser" element={<AddUser/>}></Route>
                                    <Route path=":name" element={<Account />}></Route>
                                </Route>
                                <Route path="*" element={<NoMatchPage />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </Layout>
            </main>
        </>
    );
};

export default RoutesComponents;
