import { create } from "zustand";

const usePersonalProfile = create((set) => ({
    personalProfile: {},
    savePersonalProfile: (v) =>
        set((state) => ({
            personalProfile: v,
        })),
    resetPersonalProfile: () => set({ personalProfile: {} }),
}));
const useEntityProfile = create((set) => ({
    entityProfile: {},
    saveEntityProfile: (v) =>
        set((state) => ({
            entityProfile: v,
        })),
    resetEntityProfile: () => set({ entityProfile: {} }),
}));

export { usePersonalProfile, useEntityProfile };
