import { Modal, Button } from "antd";
import cx from "classnames";

const GeneralModal = ({
    okButtonClass,
    customHandleOk,
    customHandleCancel,
    title,
    description,
    okBtnContent = "Delete",
    cancelBtnContent = "Cancel",
    isOkLoading=false,
    open,
    setOpen
}) => {

    const defaultHandleOk = () => {
        setOpen(false);
    };

    const defaultHandleCancel = () => {
        setOpen(false);
    };

    const handleOk = customHandleOk ?? defaultHandleOk;
    const handleCancel = customHandleCancel ?? defaultHandleCancel;

    return (
        <Modal
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="!max-w-[394px]"
        >
            <p className="text-content-1 font-brand text-xl">{title}</p>
            <p className="text-content-1 font-inter text-base mt-4">
                {description}
            </p>
            <div className="mt-10 flex items-center justify-between w-full">
                <Button
                    type="text"
                    className="w-[157px] !py-6"
                    onClick={handleCancel}
                >
                    {cancelBtnContent}
                </Button>
                <Button
                    className={cx(
                        "w-[157px] !py-6 bg-red text-secondary-white",
                        okButtonClass
                    )}
                    onClick={handleOk}
                    loading={isOkLoading}
                >
                    {okBtnContent}
                </Button>
            </div>
        </Modal>
    );
};

export default GeneralModal;
