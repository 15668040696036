import axios from "axios";
import serverConfig from "./config";
import _ from "lodash";
import { message } from "antd";

// 创建 axios 请求实例
const serviceAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // 基础请求地址
    timeout: 10000, // 请求超时设置
    // withCredentials: false, // 跨域请求是否需要携带 cookie
});

// 创建请求拦截
serviceAxios.interceptors.request.use(
    (config) => {
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["X-Requested-With"] = "XMLHttpRequest";
        config.headers["lang"] = !!localStorage.getItem("language") && localStorage.getItem("language") == "zh" ? "cn" : "en";
        config.headers["country"] = "1";
        if(!!localStorage.getItem('payAuth')){
            const {userId,accessToken}=JSON.parse(localStorage.getItem('payAuth'))
            config.headers["userId"] = userId;
            config.headers["accessToken"] = accessToken;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// 创建响应拦截
serviceAxios.interceptors.response.use(
    (res) => {
        let data = res.data;
        // 处理自己的业务逻辑，比如判断 token 是否过期等等
        // 代码块
        if (!data.success && data.code != 0) {
            // -1 通用错误
            // 10000 登录失效
            switch (data.code) {
                case -100: 
                    // activation session expired
                    if (localStorage.getItem('payAuth') !== null) {
                        localStorage.removeItem('payAuth')
                    }
                    throttleTip(data.msg);
                    window.location.href = window.location.origin + '/page#/login';
                case 10000:
                    // 清除session
                    throttleTip('Login session expired, please login again!');
                    // throttleTip('登录会话已过期，请重新登录！');
                    if (localStorage.getItem('payAuth') !== null) {
                        localStorage.removeItem('payAuth')
                    }
                    window.location.href = window.location.origin + '/page#/login';
                    // 刷新当前页面
                    break;
                case 10011:
                    return data
                case 10018:
                    return data;
                case -10682:
                    return data;
                case 20000:
                    return data;
                case 20001:
                    return data;
                default: // 通用错误提示
                    message.error(data.msg);
            }
            // 进入catch
            throw new Error(data.msg);
        }
        return data;
    },
    (error) => {
        console.log(error,'data@error',error)
        let message = "";
        if (error && error.response) {
            switch (error.response.status) {
                case 302:
                    message = "接口重定向了！";
                    break;
                case 400:
                    message = "参数不正确！";
                    break;
                case 401:
                    message = "您未登录，或者登录已经超时，请先登录！";
                    break;
                case 403:
                    message = "您没有权限操作！";
                    break;
                case 404:
                    message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    message = "请求超时！";
                    break;
                case 409:
                    message = "系统已存在相同数据！";
                    break;
                case 500:
                    message = "服务器内部错误！";
                    break;
                case 501:
                    message = "服务未实现！";
                    break;
                case 502:
                    message = "网关错误！";
                    break;
                case 503:
                    message = "服务不可用！";
                    break;
                case 504:
                    message = "服务暂时无法访问，请稍后再试！";
                    break;
                case 505:
                    message = "HTTP 版本不受支持！";
                    break;
                default:
                    message = "异常问题，请联系管理员！";
                    break;
            }
        }
        return Promise.reject(message);
    }
);

/**
 * 错误弹窗 15s 一次
 * @param {*} msg 
 * @returns 
 */
const throttleMsg = _.throttle(function (msg) {
    message.error(msg);
  }, 15000);
  const throttleTip = _.throttle(function (msg) {
    message.warning(msg);
  }, 15000);
export default serviceAxios;
