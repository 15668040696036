import { useState, useEffect } from "react";
import { Button as AButton,ConfigProvider } from "antd";
import "./styles/v-button.css";
const Button = (props) => {

    return (
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            /* here is your component tokens */
                        },
                    },
                }}
            >
                <AButton
                    {...props}
                >
                </AButton>
            </ConfigProvider>
    );
};
export default Button;
