const sendMoney = {
    sendMoneyBy: "Send Money by",
    singleEft: "Single EFT",
    batchEft: "Batch EFT",
    wire: "Wire",
    wireTransfer: "Wire Transfer",
    sendMoney: "Send Money",
    electronicFundTransfer: "Electronic Fund Transfer",
    availableAmount: "Available Amount",
    dailyAvailable: "Daily Available",
    send: "Send",
    sendSingleEft: "Send Single EFT",
    to: "To",
    beneficiary: "Beneficiary",
    provideDetails: "Please provide your details",
    amount: "Amount",
    available: "Available",
    transactionType: "Transaction Type",
    transactionDate: "Date of Transaction",
    paymentPurpose: "Purpose of Payment",
    description: "Description",
    sundryInfo: "Sundry Information (optional)",
    clear: "Clear",
    submit: "Submit",
    review: "Review",
    reviewDetails: "Review Transfer Details",
    reviewDetailsDesc:
        "Before transferring, double check that the details are correct.",
    confirm: "Confirm",
    cancel: "Cancel",
    transferSubmitted: "Transfer Submitted",
    transactionNumber: "Transaction Number: ",
    transferSubmittedDesc:
        "Transfer request will be reviewed by an Authorized Signatory or Admin for approval. A notification has been sent to them.",
    done: "Done",
    viewTransaction: "View Transaction",
    transaction: "Transaction",
    sendBathEFT: "Send Bath EFT",
    uploadBatchEFT: "Upload Batch EFT",
    upload: "Upload",
    uploadFilesDesc1: "xlsx only. Max file size 6MB",
    uploadFilesDesc2: "Drag and drop your batch file here or click here",
    prevFiles: "Previous Files",
    fileName: "File Name",
    uploadTime: "Upload time",
    action: "Action",
    eftTemplate: "ETF Template",
    sendBatchEFT: "Send Batch EFT",
    sendWire: "Send Wire",
    credit: "Credit",
    totalSend: "Total Send",
    fees: "Fees",
    totalAmount: "Total Amount",
    exceedAmount: "Please enter an amount within your daily available.",
    clearTransac: "Clear Transaction",
    clearTransacDesc: "Are you sure you want to clear this transaction?",
    changeTransac: "Change transaction detail",
    changeTransacDesc: "Do you want to change this transaction detail?",
    walletTrans: "Wallet Transfer",
    walletTransDesc: "To another VirgoPay user",
    walletIdInputPlaceholder: "Beneficiary Virgo Pay Wallet ID",
    back: "Back",
    yes: "Yes",
    no: "No"
};

export default sendMoney;
