import serviceAxios from "./index";
import blobInstance from "./blobInstance";
export default class AccountAPI {
    static getProfile() {
        return serviceAxios.get("dashboard/profile");
    }

    static getRoleList() {
        return serviceAxios.get("dashboard/rolesAndPermission/getRoleList");
    }

    static getCountryList() {
        return serviceAxios.get("country/getList");
    }

    static getProvinceList(countryId) {
        return serviceAxios.get(
            `country/getProvinceListByCountryId/${countryId}`
        );
    }

    /** roles and permission */
    static getRolesAndPermissionList(payAccountId) {
        return serviceAxios.get(`dashboard/rolesAndPermission/list`);
    }

    static addUser(data) {
        return serviceAxios.post("dashboard/rolesAndPermission/addUser", data);
    }

    static updateUserDetails(data) {
        return serviceAxios.post(
            "dashboard/rolesAndPermission/updateUserDetails",
            data
        );
    }

    static userDetails(data) {
        return serviceAxios.post(
            "dashboard/rolesAndPermission/userDetails",
            data
        );
    }

    static cancelInvite(payAccountUserId) {
        return serviceAxios.post(
            `dashboard/rolesAndPermission/cancelInvite/${payAccountUserId}`
        );
    }

    static sendActiveNoticeEmail(payAccountUserId) {
        return serviceAxios.post(
            `dashboard/rolesAndPermission/sendActiveNoticeEmail/${payAccountUserId}`
        );
    }

    /** settings */
    static sendSms() {
        return serviceAxios.get("sms/sendSms");
    }
    static sendEmailCode() {
        return serviceAxios.get("email/sendEmailCode");
    }
    /*
        private String nonce;
        private String email;
        private String password;
        private String googleKey;
        private String smsCode;
        private String emailCode;
        private Long googleCode;
     */
    static resetGoogleCodeFirst(data) {
        return serviceAxios.post(`user/resetGoogleCodeFirst`, data);
    }
    static resetGoogleCodeSecond(data) {
        return serviceAxios.post(`user/resetGoogleCodeSecond`, data);
    }
    static getGoogleQRBarWithToken() {
        return serviceAxios.post("user/getGoogleQRBarWithToken");
    }
    /*
        private String currentPassword;
        @Size(min=6, max=16)
        private String newPassword;
        @Size(min=6, max=16)
        private String confirmPassword;
        private String smsCode;
        private String emailCode;
        private Long googleCode;
    */
    static changeLoginPassword(data) {
        return serviceAxios.post(`user/changeLoginPassword`, data);
    }

    /**
        private String smsCode;
        private String emailCode;
        private Long googleCode;
     */
    static unbindGoogleKey(data) {
        return serviceAxios.post(`user/unbindGoogleKey`, data);
    }
    // type=2 : seting
    static sendPhoneCodeToBindPhone(data) {
        return serviceAxios.post("sms/sendPhoneCodeToBindPhone", {...data,type:2});
    }

    /**
     * 
     * @param {
     * private String phone;
     * private String password;
     * private String smsCode;
     * private String emailCode;
     * private Long googleCode;
     * private String areaCode;
     * } data 
     * @returns 
     */
    static bindOrResetPhone(data) {
        return serviceAxios.post(`user/bindOrResetPhone`, data);
    }

    /**
     * 
     * @param {
     * private String googleKey;
     * private String smsCode;
     * private String emailCode;
     * private Long googleCode;
     * } data 
     * @returns 
     */
    static addGoogleCode(data) {
        return serviceAxios.post(`user/addGoogleCode`, data);
    }

    static checkUserAuthenticateInfo() {
        return serviceAxios.get(`security/checkUserAuthenticateInfo`);
    }
    static getFeeStatementList() {
        return serviceAxios.get("feeStatements/getFeeStatementList");
    }

    static downLoadFeeStatementPdf(id) {
        return blobInstance.get(
            `feeStatements/downLoadFeeStatementPdf/${id}`,
            { responseType: "blob" }
        );
    }

    static downloadTutorialPdf() {
        return serviceAxios.get("security/getDownloadLink")
    }
}
