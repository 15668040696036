import common from "./en/common";
import dashboard from "./en/dashboard";
import transaction from "./en/transaction";
import account from "./en/account";
import login from "./en/login";
import layout from "./en/layout";
import beneficiary from "./en/beneficiary";
import sendMoney from "./en/sendMoney";
import personalInfo from "./en/personalInfo";
import notification from "./en/notification";

const en = {
    common,
    login,
    dashboard,
    transaction,
    account,
    layout,
    beneficiary,
    sendMoney,
    personalInfo,
    notification,    
};

export default en;
