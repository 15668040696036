const common = {
    test: "test",
    //get code
    getCode: "Send Code",
    resend: "Resend ({{state}}s)",
    myAcc: "My Account",
    logout: "Log Out",

    // upload component
    uploader1: "Drag & Drop files or ",
    uploader2: "Upload",
    uploaderComment: "png, jpg, jpeg and pdf only. Max file size 15MB",
    FileTypeLimit: `You can only upload JPG/PNG/PDF file!`,
    FileSizeLimit: `File must be smaller than 15MB!`,

    // not found
    notFoundH: "Page not found",
    notFoundC: "Sorry, we can’t find the page you are looking for. Please check your URL.",

    //header
    OTCDirect: "OTC Direct",
    ChatBroker: "Chat Broker",
    Learn: "Learn",
    Earn$200: "Earn $200+",
    welcome: "Welcome back",

    // rule
    plsEnter: "Please enter ",
    delete: "Delete",

    // 404 page
    error404: "Error 404",
    pageNotFound: "Page not found",
    goBackMessage: "Please, go back to the previous page or go to ",
    mainPage:'the main page.',
};
export default common;
