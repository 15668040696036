const dashboard = {
    hello: "早安，约翰!",
    totalBalance: "Total Balance",
    asOn: "as on ",
    availableBalance: "Available Balance",
    liquidityReserve: "Liquidity Reserve",
    notification: "Notification",
    pendingBalance: "Pending Balance",
    createABeneficiary: "Create a beneficiary",
    sendSingleEFT: "Send Single EFT",
    sendWire: "Send Wire",
    sentEFT: "Sent EFT",
    sentWire: "Sent Wire",
    receivedEFT: "Received EFT",
    dateRange: "Date Range",
    type: "Type",
    status: "Status",
    clearFilter: "Clear filter",
    viewCompleteHistory: "View complete history",
    number: "Number",
    receiver: "Receiver",
    dateAndTime: "Date and Time",
    amount: "Amount",
    description: "Description",
    wireStatement: "Wire Statement",
};
export default dashboard;
